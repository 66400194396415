import * as React from "react"
import {useSpring} from 'react-spring'
import {AnimatedButtonProps} from 'src/components/AnimatedButton'
import LinksLayout from 'src/components/LinksLayout'
import {fadeInFromLeftConfig, fadeInFromRightConfig} from 'src/utils/animations'

const SetupPage = (props) => {
  const fadeInFromLeft = useSpring(fadeInFromLeftConfig)
  const fadeInFromRight = useSpring(fadeInFromRightConfig)

  const linkDataList: AnimatedButtonProps[] = [
    {
      backgroundImageData: props.data.PcBuild.childImageSharp.fluid,
      page: '/links/setup/pc',
      fadeSpringClass: fadeInFromLeft,
      header: 'PC Build',
    },
    {
      backgroundImageData: props.data.Monitors.childImageSharp.fluid,
      page: '/links/setup/monitors',
      fadeSpringClass: fadeInFromRight,
      header: 'Monitors',
    },
    {
      backgroundImageData: props.data.KeyboardMouse.childImageSharp.fluid,
      page: '/links/setup/keyboard-mouse',
      fadeSpringClass: fadeInFromLeft,
      header: 'Keyboard and Mouse',
    },
    {
      backgroundImageData: props.data.DeskChair.childImageSharp.fluid,
      page: '/links/setup/desk-chair',
      fadeSpringClass: fadeInFromRight,
      header: 'Desk and Chair',
    },
    {
      backgroundImageData: props.data.AudioEquipment.childImageSharp.fluid,
      page: '/links/setup/audio',
      fadeSpringClass: fadeInFromLeft,
      header: 'Audio Equipment',
    },
    // {
    //   backgroundImageData: props.data.Lighting.childImageSharp.fluid,
    //   page: '/links/setup/lighting',
    //   fadeSpringClass: fadeInFromRight,
    //   header: 'Lighting',
    // },
    // {
    //   backgroundImageData: props.data.Camera.childImageSharp.fluid,
    //   page: '/links/setup/camera',
    //   fadeSpringClass: fadeInFromLeft,
    //   header: 'Camera Equipment',
    // },
    // {
    //   // backgroundImageData: props.data.DesktopSetup.childImageSharp.fluid,
    //   page: '/links/setup/peripherals',
    //   fadeSpringClass: fadeInFromRight,
    //   header: 'Peripherals (remove?)',
    // },
  ]

  return <LinksLayout linkDataList={linkDataList} />
}

export default SetupPage

export const query = graphql`
  query {
    PcBuild: file(relativePath: { eq: "setup/pcbuild.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Monitors: file(relativePath: { eq: "setup/monitors.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    DeskChair: file(relativePath: { eq: "setup/deskchair.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    KeyboardMouse: file(relativePath: { eq: "setup/keyboardmouse.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    AudioEquipment: file(relativePath: { eq: "setup/audioequipment.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Lighting: file(relativePath: { eq: "setup/lighting.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Camera: file(relativePath: { eq: "setup/camera.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`

/*
Setup:
- Monitors
--- LG
--- Dell
--- Dell
--- TCL tv
--- Vivo Large Arm
--- Vivo Small Arm

- Keyboard & Mouse
--- ZSA Moonlander
--- Logitech MX Master 3
--- Keychron 
--- Mouse Mat

- Desk
--- ProgressiveDesk Standing frame
--- Linnmon table top
--- Alex drawers

- Audio
--- Yamaha HS8
--- Focusrite Scarlett 2i4
--- Audio Technica M50x

- Herman Miller Embody

- Accessories
--- BenQ Screenbar
--- Ulbre Usb Switcher

- PC Build
--- AMD 5950x
--- Gigabyte Vision 3090
--- Lian Li Galahad 360
--- 64GB Crucial Ballistix RGB
--- Asrock b550
--- Corsair SF750
--- Lian Li 011D Mini

- Camera
--- Sony a6600
--- Sigma 16mm
--- Sigma 30mm
--- Sigma 56mm
--- Sony 16-235mm

*/